import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86')
];

export const server_loads = [0,4,2,5];

export const dictionary = {
		"/": [6],
		"/admin/api": [~53,[4]],
		"/admin/curriculum/[...license]": [~54,[4]],
		"/admin/invites": [~55,[4]],
		"/admin/license/[...license]": [~56,[4]],
		"/admin/reminders": [~57,[4]],
		"/admin/renew": [~58,[4]],
		"/admin/reports": [~59,[4]],
		"/admin/upgrade": [~60,[4]],
		"/book": [61],
		"/[[embed]]/(content)/card/[vulnerability]": [~7],
		"/confirm/[token]": [~62],
		"/enterprise": [63],
		"/features": [64],
		"/glossary": [65],
		"/glossary/[term]": [66],
		"/invite/[token]": [~67],
		"/[[embed]]/(content)/lessons": [8,[2]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/ai-bias-and-unreliability/[...stage]": [9,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/ai-data-extraction-attacks/[...stage]": [10,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/ai-prompt-injection/[...stage]": [11,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/broken-access-control/[...stage]": [12,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/buffer-overflows/[...stage]": [13,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/click-jacking/[...stage]": [14,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/command-execution/[...stage]": [15,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/csrf/[...stage]": [16,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/denial-of-service-attacks/[...stage]": [17,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/directory-traversal/[...stage]": [18,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/dns-poisoning/[...stage]": [19,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/downgrade-attacks/[...stage]": [20,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/email-spoofing/[...stage]": [21,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/file-upload/[...stage]": [22,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/host-header-poisoning/[...stage]": [23,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/information-leakage/[...stage]": [24,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/insecure-design/[...stage]": [25,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/lax-security-settings/[...stage]": [26,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/logging-and-monitoring/[...stage]": [27,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/malvertising/[...stage]": [28,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/mass-assignment/[...stage]": [29,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/open-redirects/[...stage]": [30,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/password-mismanagement/[...stage]": [31,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/privilege-escalation/[...stage]": [32,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/prototype-pollution/[...stage]": [33,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/regex-injection/[...stage]": [34,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/remote-code-execution/[...stage]": [35,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/session-fixation/[...stage]": [36,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/sql-injection/[...stage]": [37,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/ssl-stripping/[...stage]": [38,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/ssrf/[...stage]": [39,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/subdomain-squatting/[...stage]": [40,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/toxic-dependencies/[...stage]": [41,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/unencrypted-communication/[...stage]": [42,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/user-enumeration/[...stage]": [43,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/weak-session/[...stage]": [44,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/xml-bombs/[...stage]": [45,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/xml-external-entities/[...stage]": [46,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/xss-dom/[...stage]": [47,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/xss-reflected/[...stage]": [48,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/xss-stored/[...stage]": [49,[2,3]],
		"/[[embed]]/(content)/lessons/(vulnerabilities)/xssi/[...stage]": [50,[2,3]],
		"/login": [~68],
		"/login/saml": [69],
		"/owasp": [70],
		"/pci": [71],
		"/[[embed]]/(content)/prevention/[vulnerability]": [~51],
		"/privacy": [72],
		"/profile": [73],
		"/purchased": [~76],
		"/purchase": [74],
		"/purchase/failure": [75],
		"/[[embed]]/(content)/quiz/[vulnerability]": [~52],
		"/reset": [77],
		"/reset/[token]": [~78],
		"/shell": [79],
		"/signup": [~80],
		"/super/companies": [~81,[5]],
		"/super/companies/[...company]": [~82,[5]],
		"/super/search/[...search]": [~83,[5]],
		"/super/users/new": [~85,[5]],
		"/super/users/[...page]": [~84,[5]],
		"/terms": [86]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';